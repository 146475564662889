import { Dialog } from "@ariakit/react";
import { Link } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { Eye, Loader2 } from "lucide-react";
import { ClientOnly } from "remix-utils/client-only";
import { MediaCarousel } from "~/components/media-carousel";
import {
  DeletePostPopoverItem,
  EditPostPopoverItem,
  Post,
  PostAvailableStickers,
  PostBody,
  PostContent,
  PostInfo,
  PostMediaFallback,
  PostMenu,
  PostMenuPopover,
  PostMenuPopoverItem,
  PostSlapBox,
  PostStickers,
  PostUser,
} from "~/components/post";
import PostCommentSection from "~/components/post-comment-section";
import { usePostComments } from "~/hooks/use-comments";
import { useCurrentUser } from "~/hooks/use-current-user";
import type { PostSticker, Post as PostType } from "~/types";
import { cn } from "~/util/css";

interface PostModalProps {
  post: PostType;
  open: boolean;
  mediaIndex: number;
  onClose: () => void;
  onDeleteSuccess?: () => void;
}

export default function PostModal(props: PostModalProps) {
  const [currentUser] = useCurrentUser();
  const { post } = props;
  const { deleteComment, loading } = usePostComments(post.id);

  const getIndividualPost = async (): Promise<{
    stickers?: PostSticker[];
    comments?: Comment[];
  }> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/posts/${post.id}`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["posts", post.id],
    queryFn: getIndividualPost,
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[80%] 2xl:w-[1214px] mx-auto overflow-hidden",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 lg:p-0 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="flex flex-col gap-4 h-full">
        <Post className="flex">
          {post.media_urls.length !== 0 && (
            <PostStickers
              className="flex-1 bg-black"
              postId={post.id}
              stickers={data?.stickers || []}
              isLoading={isLoading}
            >
              <ClientOnly>
                {() => (
                  <PostSlapBox postId={post.id} className="md:max-w-full" />
                )}
              </ClientOnly>
              <ClientOnly fallback={<PostMediaFallback />}>
                {() => (
                  <MediaCarousel
                    mediaUrls={post.media_urls}
                    currentIndex={props.mediaIndex}
                    className="md:max-w-full lg:max-h-[800px]"
                  />
                )}
              </ClientOnly>
            </PostStickers>
          )}
          <div className="w-[300px] xl:w-[384px]  bg-black hidden flex-col lg:flex">
            <PostContent>
              <PostInfo>
                <PostUser
                  avatarUrl={post.user_avatar_url || "/default-avatar.svg"}
                  username={post.user_username}
                />
                <PostMenu
                  createdAt={new Date(post.created_at).toISOString()}
                  stickerCount={data?.stickers?.length || 0}
                >
                  <PostMenuPopover>
                    <PostMenuPopoverItem asChild>
                      <Link to={`/post/${post.id}`}>
                        <Eye /> View Post
                      </Link>
                    </PostMenuPopoverItem>
                    {post.user_id === currentUser?.id && (
                      <>
                        <EditPostPopoverItem
                          postId={post.id}
                          postContent={post.content}
                        />
                        <DeletePostPopoverItem
                          postId={post.id}
                          navigateTo="/"
                          onDeleteSuccess={() => {
                            if (props.onDeleteSuccess) {
                              props.onDeleteSuccess();
                            }
                          }}
                        />
                      </>
                    )}
                  </PostMenuPopover>
                </PostMenu>
              </PostInfo>
              {post.content && (
                <Link to={`/post/${post.id}`}>
                  <PostBody contentBody={post.content} />
                </Link>
              )}
              {currentUser && (
                <PostAvailableStickers
                  postId={post.id}
                  ownerId={post.user_id}
                  maxDisplayStickers={5}
                />
              )}
            </PostContent>
            <div className="bg-white py-2 px-4 flex-col gap-4  hidden lg:flex flex-1">
              <div className="h-[1px] w-full bg-gray-300" />
              {loading ? (
                <div className="flex items-center justify-center w-full h-full gap-2">
                  <Loader2
                    size={34}
                    className="animate-spin animate text-pink-300"
                  />
                  Loading comments...
                </div>
              ) : (
                <PostCommentSection
                  className="flex-[1_1_0] max-h-full"
                  postId={post.id}
                  onDeleteComment={deleteComment}
                  emptyElement={
                    <div className="bg-[#CBE3FF] rounded-xl px-4 py-2 flex flex-col">
                      No comments yet.
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </Post>
      </div>
    </Dialog>
  );
}
